import React from "react"
import { graphql, Link } from 'gatsby'
import Image from 'gatsby-image'
import Layout from '../components/layout'
import SEO from "../components/SEO"

const Subjects = ({ data }) => {
  const subjects = data.subjects.edges
  return (
    <Layout>
      <SEO title="Useful Information" description="Useful information about health, beauty and style" />
      <h1>Useful Information</h1>
      <p>In this section of the site we provide useful information about health, beauty and style.</p>
      {subjects.map(({ node }) => {
        return (
          <div key={node.slug} className="linkBlock">
            {node.mainImage ?
              <Link to={`/s/${node.slug}`}>
                <Image fluid={node.mainImage.fluid} className="mainImgPic" alt={node.title} />
              </Link>
              : null
            }
            <h2><Link to={`/s/${node.slug}`}>{node.title}</Link></h2>
            <p>{node.summary.summary}</p>
            <div className="clear" />
          </div>
        )
      })}
    </Layout>
  )
}

export const getSubjects = graphql`
  query {
    subjects: allContentfulSubject(
        filter: { nn: { ne: null } },
        sort: { fields: nn, order: ASC }
      ) {
      edges {
        node {
          title
          slug
          summary {
            summary
          }
          mainImage {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`
export default Subjects
